<template>
  <div>
    <v-carousel
      class="detail-carousel"
      cycle
      height="252px"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item v-for="(content, i) in contents" :key="i">
        <v-sheet :color="colors[i]" height="100%">
          <v-row class="fill-height" align="center" justify="center">
            <div class="body-detail-date">
              <div class="row">
                <div class="col-8">
                  <div class="content-details">

                    <div class="content-section">
                      <h1>{{ content.title }}</h1>
                    <p>
                      {{ content.short_description }}
                    </p>
                    </div>
                    

                    <div class="button-section">
                      <!-- <div v-if="content.video_link" class="main-button">
                      <a :href="content.video_link" target="blank">
                        <div class="link-button">
                          <button>Watch</button>
                        </div>
                      </a>
                    </div>
                    <div v-else></div> -->

                      <div class="main-button">
                        <div class="link-button">
                          <button @click="openVideo(content)">Watch</button>

                          <!-- <v-btn @click="openVideo(content)"> Play </v-btn> -->
                        </div>
                      </div>

                      <div class="main-button">
                        <!-- <a :href="content.video_link" target="blank"> -->
                        <div class="link-button">
                          <button
                            @click="
                              $router.push({
                                name: 'admin-info-detail',
                                params: { id: content.id },
                              })
                            "
                          >
                            Details
                          </button>
                        </div>

                        <!-- </a> -->
                      </div>

                      <!-- <div class="main-button">
                        <div class="link-button">
                          <button
                            @click="
                              $router.push({
                                name: 'example-file',
                              })
                            "
                          >
                            Example
                          </button>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>

                <div class="col-4">
                  <div class="calendar-details">
                    <div class="calendar-img">
                      <img :src="content.image" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="text-h2">{{ slide }} Slide</div> -->
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

    <v-dialog v-model="videoIsOpen" persistent max-width="900px">
      <v-card>
        <v-card-title
          class="primary white--text"
          style="display: flex; justify-content: space-between"
        >
          <span class="title">{{ selectedContent.title }}</span>
          <v-btn color="white" text @click="videoIsOpen = false">Close</v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <iframe
            width="100%"
            height="600"
            :src="'https://www.youtube.com/embed/' + selectedContent.video_link"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {
    // ModalVideo,
  },
  data() {
    return {
      colors: [],
      slides: ["First", "Second", "Third"],
      contents: [],
      dialog: false,
      selectedContent: {},
      videoIsOpen: false,
      isModalVisible: false,
    };
  },

  mounted() {
    this.getContent();
  },

  methods: {
    getContent() {
      this.$rest.get("/api/content-management").then(({ data }) => {
        this.contents = data.data;
      });
      // console.log("here");
    },
    openVideo(content) {
      this.selectedContent = content;
      this.videoIsOpen = !this.videoIsOpen;
    },
  },
  computed: {
    videoId: function () {
      //Logic to extract based on URL
      return "https://www.youtube.com/watch?v=HL0ShTRltrA&ab_channel=VillageEnvironmentNEPAL";
    },
  },
};
</script>

<!-- <style lang="scss">
.slider-section {
  height: 250px !important;
  border-radius: 6px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;

  .fill-height {
    height: 100%;
    display: flex;
    .slider-complete-section {
      height: 98%;
      width: 95%;
      padding: 31px 20px 20px 20px;
      background-color: #fff;
      box-shadow: 10px 10px 5px #aaaaaa;
      border: thin solid rgba(0, 0, 0, 0.12);
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
        0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;

      .slider-content-section {
        margin-top: 20px;
        h1 {
          color: #00b2d6;
          font-size: 22px;
          margin-bottom: 10px;
          font-weight: bold;
        }

        p {
          font-size: 12px;
          color: #8f8e8e;
          font-weight: 500;
        }

        button {
          color: #fff;
          font-weight: bold;
          background: #0099b7;
          height: 36px;
          width: 120px;
          //   padding: 6px 12px;
          border-radius: 4px;
          font-size: 12px;
        }
      }

      .slider-image-section {
        width: 100%;
        height: 100%;
        margin-top: 4px;
        img {
          height: 105%;
          width: 100%;
          object-fit: cover;
          border-radius: 8px;
        }
      }
    }
  }
}
.v-window .v-item-group .theme--dark .v-carousel {
  border-radius: 10px;
  height: 200px;
}
.v-carousel__controls {
  display: none;
  //   bottom: 12px;
  //   display: none;
}

// .v-application--is-ltr .v-window__prev {
//   left: -10px;
//   height: 26px;
//   width: 26px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .v-application--is-ltr .v-window__next {
//   right: -10px;
//   height: 26px;
//   width: 26px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.v-application--is-ltr .v-window__prev {
  left: 365px;
  right: 40px;
  top: 12px;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-application--is-ltr .v-window__next {
  top: 12px;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .v-icon .notranslate .theme--dark {
//   height: 20px !important;
//   width: 20px !important;
// }

.v-btn__content {
  .v-icon {
    height: 22px !important;
    width: 22px !important;
  }
}

.v-icon {
  //   height: 20px !important;
  //   width: 20px !important;
}

.text-h2 {
  color: #fff;
}
</style> -->

<style lang="scss">
.body-detail-date {
  // background-color: #058373;
  background-color: #1664c0;
  padding: 30px;
  // border-radius: 12px;
  display: flex;
  // min-height: 250px;
  width: 100%;
  height: 100%;

  .content-details {
    .content-section{
      min-height: 125px;
      h1 {
      color: #fff;
      font-size: 25px;
      margin-bottom: 10px;
      width: 12em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    p {
      color: #c9ddf4;
      font-weight: 500;
      font-size: 15px;
      margin-bottom: 20px;
      display: -webkit-box;
      max-width: 215px;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    }
    

    .button-section {
      display: flex;
      margin-top: 30px;
      .main-button {
        width: 40%;

        .link-button {
          // background-color: #fff;
          // // padding: 9px 15px;
          // color: #1664c0;
          // // margin-right: 20px;
          // border-radius: 10px;
          // font-size: 15px;
          // width: 50%;
          // font-weight: 500;
          // // margin-top: 10px;
          // text-align: center;
          button {
            padding: 9px 15px;
            margin-top: 10px;
            margin-right: 20px;
            background-color: #fff;
            color: #1664c0;
            border-radius: 10px;
            font-size: 15px;
            font-weight: 500;
            text-align: center;
            width: 90%;
          }
        }
      }
    }
  }

  .calendar-details {
    .tick-setting {
      display: flex;
      justify-content: right;
      // justify-content: space-between;

      .tick-box {
        height: 10px;
        width: 10px;
        padding: 8px;
        background-color: #48aea2;
        margin-right: 10px;
      }
    }
  }

  .calendar-img {
    text-align: center;
    margin-top: 80px;
    border: 2px solid #fff;
    border-radius: 10px;

    background: #fff;

    img {
      height: 120px;
      width: 120px;
      border-radius: 10px;
      object-fit: cover;
    }
  }
}

.detail-carousel {
  background-color: #1664c0;
  border-radius: 5px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}

.v-application--is-ltr .v-window__prev {
  display: none;
}

.v-application--is-ltr .v-window__next {
  display: none;
}

.v-carousel__controls__item {
  margin: 0 2px;
}

.v-carousel__controls {
  // width: 0%;
  display: flex;
  justify-content: right;
  top: 12px;
  right: 15px !important;
  left: auto;
  border-radius: 4px;
  height: 30px;
  background-color: transparent;
}

.v-carousel__controls__item {
  border-radius: 0;
  // width: 12px;
  // height: 12px;
}

.v-icon__svg {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0px !important;
  // background: #fff;
}

.v-btn--icon.v-size--small {
  border-radius: 50%;
}
</style>
<!-- <script>
export default {
  data: () => ({
    model: 0,
    colors: ["primary", "secondary", "yellow darken-2"],
  }),
};
</script> -->
